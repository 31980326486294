@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital@0;1&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,700;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@1,400;1,500&display=swap");

$primary_color: #ffe8d6;
$secondary_color: #6b705c;

// $primary_color: #ecf8f8;
// $secondary_color: #e6beae;

// $primary_color:#edf2fb;
// $secondary_color:#abc4ff;

// $primary_color:#edf2fb;
// $secondary_color:#abc4ff;

// $primary_color:#e5e5e5;
// $secondary_color:#1d3557;

// $primary_color:#e9c46a;
// $secondary_color:#2a9d8f;

// @font-face {
//     font-family: "Sukar black";
//     font-style: normal;
//     font-weight: normal;
//     src: local("Sukar black"), url("../fonts/SukarBlack.woff") format("woff");
// }
@font-face {
    font-family: "Sukar Regular";
    font-style: normal;
    font-weight: normal;
    src: local("Sukar Regular"), url("../fonts/SukarRegular.woff") format("woff");
}
.mainWrapper {
    // font-family: "Poppins", sans-serif;
    font-family: "Sukar Regular", sans-serif;
    font-weight: 100;
    background-color: $primary_color;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    transition: 2s;
    animation: scalee 1.5s;
    @keyframes scalee {
        from {
            opacity: 0;
            // transform: opacity(0);
            // transform-origin: top left;
        }
        to {
            opacity: 1;
        }
    }

    //Quadrant Starts
    .quadrant {
        width: 100%;
        background-color: $primary_color;
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100vh;
        margin: 0px;
        .top {
            display: flex;
            height: 50%;
            .topLeft {
                width: 50%;
                height: 100%;
                text-align: center;
                position: relative;
                cursor: pointer;
                background-color: #ffe8d6;
                text-decoration: none;
                color: #6b705c;
                overflow: hidden;
                h1 {
                    font-family: "Alegreya", serif;
                    font-size: 5.2vw;
                    position: absolute;
                    transform: translateY(-50%);
                    top: 50%;
                    font-style: italic;
                    width: 100%;
                    color: gray;
                }

                .text {
                }
                .image {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    img {
                    }
                }
                img {
                    width: 25%;
                    position: absolute;
                    bottom: -23%;
                    right: -12%;
                }
            }
            .topLeft:hover {
                opacity: 0.6;
            }
            .topLeft.boxx.active {
                width: 100%;
                height: 100vh;
            }
            .topLeft.boxx.none {
                display: none;
            }
            .topRight {
                width: 50%;
                position: relative;
                cursor: pointer;
                height: 100%;
                text-align: center;
                text-decoration: none;
                color: white;
                background-color: #6b705c;
                overflow: hidden;
                // font-family: ;
                img {
                    width: 25%;
                    position: absolute;
                    bottom: -23%;
                    left: -12%;
                }
                h1 {
                    font-family: "Alegreya", serif;
                    font-size: 5.2vw;
                    position: absolute;
                    transform: translateY(-50%);
                    top: 50%;
                    font-style: italic;
                    width: 100%;
                    color: wheat;
                }
                .text {
                }
                .image {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    img {
                    }
                }
            }
            .topRight:hover {
                opacity: 0.9;
            }
            .topRight.boxx.active {
                width: 100%;
                height: 100vh;
            }
            .topRight.boxx.none {
                display: none;
            }
        }
        .bottom {
            display: flex;
            height: 50%;

            .bottomRight {
                width: 50%;
                height: 100%;
                position: relative;
                cursor: pointer;
                text-align: center;
                color: #6b705c;
                background-color: #ffe8d6;
                text-decoration: none;
                overflow: hidden;

                img {
                    width: 25%;
                    position: absolute;
                    top: -23%;
                    right: -12%;
                }
                h1 {
                    font-family: "Alegreya", serif;
                    font-size: 5.2vw;
                    position: absolute;
                    transform: translateY(-50%);
                    top: 50%;
                    font-style: italic;
                    width: 100%;
                    color: gray;
                }
                .text {
                }
                .image {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    img {
                    }
                }
            }
            .bottomRight:hover {
                opacity: 0.6;
            }
            .bottomRight.boxx.active {
                width: 100%;
                height: 100vh;
            }
            .bottomRight.boxx.none {
                display: none;
            }
            .bottomLeft {
                width: 50%;
                height: 100%;
                position: relative;
                cursor: pointer;
                text-align: center;
                text-decoration: none;
                color: white;
                background-color: #6b705c;
                overflow: hidden;

                img {
                    width: 25.5%;
                    position: absolute;
                    top: -23.6%;
                    left: -12.7%;
                }
                h1 {
                    font-family: "Alegreya", serif;
                    font-size: 5.2vw;
                    position: absolute;
                    transform: translateY(-50%);
                    top: 50%;
                    font-style: italic;
                    width: 100%;
                    color: wheat;
                }
                .text {
                }
                .image {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }
            }
            .bottomLeft:hover {
                opacity: 0.9;
            }
            .bottomLeft.boxx.active {
                width: 100%;
                height: 100vh;
            }
            .bottomLeft.boxx.none {
                display: none;
            }
        }
        .centerImage {
            width: 13%;
            // height: 100px;
            border-radius: 50px;
            // background-color: coral;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: 1s;
            animation: rotate 2.8s;

            // overflow: hidden;
            // transform: skew(20deg);
            // img {
            //     transform: translate(0%, -23%) rotate(360deg);
            //     width: 100%;
            // }
        }
        // .rotate{
        //     transform:rotate(360deg)
        // }
        a.blogLink {
            position: absolute;
            bottom: 2%;
            left: 50%;
            transform: translateX(-50%);
            background-color: #ffffff;
            padding: 10px;
            border-radius: 25px;
            text-decoration: none;
            color: black;
            text-align: center;
        }
        a:hover.blogLink {
            box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
            -webkit-box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
        }
    }
    //Quadrant Ends

    .topBar {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 40px;
        .title {
            font-family: "Alegreya", serif;

            font-style: italic;
            font-size: 55px;
            border-bottom: 1px solid;
            width: 80%;
            text-align: center;
            padding-bottom: 30px;
        }
        .wheelOfFortune {
            text-align: center;
            img {
                width: 30%;
                border-radius: 50%;
                animation: wheel 2s linear infinite;
                @keyframes wheel {
                    from {
                        transform: rotate(0deg);
                        // transform-origin: top left;
                    }
                    to {
                        transform: rotate(360deg);
                    }
                }
            }
            img:hover {
                cursor: pointer;
                // box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
            }
        }
        .navBar {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            width: 45%;
            font-size: 14px;
            font-weight: 300;
            padding: 10px;
            a {
                cursor: pointer;
                text-decoration: none;
                color: black;
            }
        }
    }
    .contentContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        padding: 0% 7%;
        img.myStoryBg {
            position: absolute;
            width: 100%;
            height: 100vw;
            opacity: 0.3;
            pointer-events: none;
        }
        // .hero {
        //     position: relative;
        //     display: flex;
        //     justify-content: space-between;
        //     align-items: center;
        //     padding-top: 90px;
        //     .sub {
        //         .image {
        //             width: 60%;
        //             height: 60vw;
        //             padding: 8px;
        //             margin: auto;
        //             order: 2;
        //             img {
        //                 width: 100%;
        //             }
        //         }
        //         .textBox {
        //             position: absolute;
        //             top: 8%;
        //             width: 80%;
        //             padding: 6%;
        //             background-color: $secondary_color;
        //             color: white;
        //             transform: translateX(-60%);
        //             h1 {
        //                 font-family: "Alegreya", serif;
        //                 font-weight: 100;
        //                 font-size: 2.7rem;
        //                 font-style: italic;
        //             }
        //             p {
        //                 padding: 2%;
        //             }
        //             a {
        //                 text-decoration: none;
        //                 text-align: center;
        //                 position: absolute;
        //                 right: 1%;
        //                 bottom: 1%;
        //                 padding: 2%;
        //                 border-radius: 20px;
        //                 color: black;
        //                 background-color: #ecf8f8;
        //                 box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
        //                 -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
        //                 -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
        //                 transition: 0.5s;
        //             }
        //             a:hover {
        //                 color: black;
        //                 right: 2%;
        //                 box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
        //                 -webkit-box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
        //                 -moz-box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
        //             }
        //         }
        //     }
        // }
        .hero {
            position: relative;
            width: 100%;
            padding: 75px 35px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .sub {
                width: 100%;
                position: relative;
                // padding: 10% 0%;
                height: 785px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                // text-align: center;
                .image {
                    position: absolute;
                    top: 35%;
                    right: 0%;
                    width: 40%;
                    text-align: center;
                    // left: 40%;
                    img {
                        width: 80%;
                    }
                }
                .textBox {
                    // position: absolute;
                    width: 100%;
                    min-height: 300px;
                    // bottom: 0;
                    top: 0;
                    // left: 5%;
                    padding: 10px 40% 10px 10px;
                    background-color: #6b705c;
                    color: white;
                    h1 {
                        font-family: "Alegreya", serif !important;
                        font-size: 56px;
                        text-align: center;
                        margin-bottom: 2%;
                        font-style: italic;
                        color: cornsilk;
                    }
                }
                a.dyr {
                    // position: absolute;
                    // right: 15%;
                    // top: 70%;
                    // transform: translate(-15%, -70%);
                    width: 20%;
                    margin-left: 20%;
                    text-decoration: none;
                    text-align: center;
                    // padding: 10px;
                    // margin-top: 15px;
                    border-radius: 20px;
                    color: black;
                    // background-color: #ecf8f8;
                    background-color: #c3ac9a8f;
                    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
                    -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
                    -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
                    transition: 0.5s;
                }
                a.dyr:hover {
                    color: black;
                    right: 15.5%;
                    // right: 2%;
                    box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
                    -webkit-box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
                    -moz-box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
                }
            }
        }
        a.dyr {
            text-decoration: none;
            text-align: center;
            padding: 10px;
            margin-top: 15px;
            border-radius: 20px;
            color: black;
            // background-color: #ecf8f8;
            background-color: #c3ac9a8f;
            box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
            -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
            transition: 0.5s;
        }
        a.dyr:hover {
            color: black;
            right: 2%;
            box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
            -webkit-box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
        }
        .testimonies {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-around;
            margin-top: -65px;
            padding: 15px;
            h1 {
                width: 100%;
                font-family: "Alegreya", serif !important;
                font-size: 56px;
                text-align: center;
                margin-bottom: 2%;
                font-style: italic;
                color: #585555;
            }
            // .testimonyBg {
            //     position: absolute;
            //     z-index: -1;
            //     width: 100%;
            //     height: 100%;
            //     img {
            //         width: 100%;
            //     }
            // }
            // .gradient {
            //     position: absolute;
            //     background-color: black;
            //     opacity: 0.2;
            // }
            .testimony {
                display: flex;
                // flex-direction: column;
                align-items: center;
                flex-wrap: wrap;
                // min-width: calc((100% - 40px) / 2) !important;
                margin-top: 15px;
                position: relative;
                background-color: #6b705c;
                padding: 30px;
                .image {
                    width: 20%;
                    // position: absolute;
                    // top: -100px;
                    // left: -100px;
                    // width: 20%;
                    // border-radius: 50%;
                    // overflow: hidden;
                    img {
                        width: 100%;
                        border-radius: 50%;
                    }
                }
                .text {
                    width: 80%;
                    color: white;
                    text-align: right;
                    font-weight: 300;
                    padding: 10px;
                    .content {
                    }
                    .author {
                        font-size: 1.2rem;
                        margin-top: 10px;
                    }
                }
            }
        }
        .contactSection {
            width: 80%;
            padding: 2%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: center;
            margin-top: 60px;
            .left {
                width: 40%;
                display: flex;
                flex-direction: column;
                span {
                    font-family: "Alegreya", serif;

                    font-size: 2rem;
                }
                a {
                    padding-top: 1%;
                    text-decoration: none;
                    color: black;
                }
            }
            .right {
                width: 48%;
                form {
                    input {
                        background: transparent;
                        border: 1px solid;
                        padding: 2%;
                        width: 100%;
                        min-height: 56px;
                        transition: 0.5s;
                    }
                    input:focus {
                        outline: none;
                    }
                    button {
                        border-radius: 20px;
                        color: black;
                        // background-color: #ecf8f8;
                        background-color: #c3ac9a8f;
                        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
                        -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
                        -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
                        transition: 0.5s;
                        width: 26%;
                    }
                    button:hover {
                        color: black;
                        // right: 15.5%;
                        // right: 2%;
                        box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
                        -webkit-box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
                        -moz-box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
                    }
                }
            }
        }

        .broucher {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background-color: white;
            width: 100%;
            .image {
                width: 50%;
                img {
                    width: 100%;
                }
            }
            .title {
                width: 50%;
                font-family: "Alegreya", serif;

                font-style: italic;
                font-size: 55px;
                text-align: center;
            }
        }

        .myStory {
            width: 92%;
            padding: 10% 15px 10px 15px;
            position: relative;
            display: flex;
            .stripedLotus {
                position: relative;
                border-radius: 2px;
                width: 15%;
                // background-color: #6b705c;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                .circle {
                    text-align: center;
                    width: 10vw;
                    height: 10vw;
                    border-radius: 50%;
                    background-color: $primary_color;
                    margin-bottom: 10px;

                    -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
                    -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
                    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
                    img {
                        width: 100%;
                    }
                }
                .circle.top {
                    position: absolute;
                    top: 10px;
                }
            }
            .content {
                width: 85%;
                padding: 40px;
                h1 {
                    font-family: "Alegreya", serif !important;
                    font-size: 56px;
                    text-align: center;
                    margin-bottom: 2%;
                    font-style: italic;
                    color: #585555;
                }
                p {
                    padding: 1%;
                    font-size: 16px;
                    font-weight: 300;
                }
            }
        }

        .codeOfEthics {
            width: 70%;
            padding: 12px;
            h3 {
                font-family: "Alegreya", serif !important;
            }
            h1 {
                font-family: "Alegreya", serif !important;
                // font-family: "Playfair Display", serif !important;
                font-size: 40px;
                font-style: italic;
                color: #585555;
            }
            span {
                margin-bottom: 10px;
            }
            ul {
                li {
                    padding: 1%;
                    font-size: 18px;
                    font-weight: 300;
                }
            }
        }
        .disclaimer {
            width: 70%;
            padding: 12px;
            h3 {
                font-family: "Alegreya", serif !important;
                // font-family: "Playfair Display", serif !important;
            }
            .text {
                padding: 2%;
                p {
                    font-size: 18px;
                    font-weight: 300;
                }
            }
        }
        .cAR {
            font-family: "Alegreya", serif !important;
            font-size: 56px;
            text-align: center;
            font-style: italic;
            margin-top: 65px;
            margin-top: 50px;
            color: #585555;
        }
        .readingsPage {
            display: flex;
            align-items: center;
            .readings {
                width: 80%;
                padding-top: 50px;
                text-align: center;
                .cAR {
                    font-family: "Alegreya", serif !important;
                    font-size: 56px;
                    text-align: center;
                    font-style: italic;
                    margin-top: 65px;
                    margin-top: 50px;
                    color: #585555;
                    margin-bottom: 90px;
                }
                p {
                    padding: 0% 1%;
                    font-size: 16px;
                    font-weight: 300;
                    text-align: left;
                }
            }
            h3 {
                font-family: "Alegreya", serif !important;
                // font-family: "Playfair Display", serif !important;
            }
            h1 {
                font-family: "Alegreya", serif !important;
                font-size: 56px;
                text-align: center;
                font-style: italic;
                color: #585555;
            }
            /* Hide scrollbar for Chrome, Safari and Opera */
            .cards::-webkit-scrollbar {
                display: none;
            }

            /* Hide scrollbar for IE, Edge and Firefox */
            .cards {
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */
            }
            .cards {
                width: 100%;
                height: 700px;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                padding: 10px;
                align-items: center;
                overflow-y: scroll;
                h1 {
                    font-family: "Alegreya", serif !important;
                    font-size: 56px;
                    text-align: center;
                    font-style: italic;
                    color: #585555;
                }
                h3 {
                    font-family: "Alegreya", serif !important;
                    font-size: 56px;
                    text-align: center;
                    margin-bottom: 2%;
                    font-style: italic;
                }
                .cardd {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 2% 5%;
                    margin: 0.5%;
                    width: 100%;
                    // width: calc((100% - 20px) / 1);
                    flex-wrap: wrap;
                    background-color: $secondary_color;
                    color: white;
                    .image {
                        width: 40%;
                        img {
                            width: 70%;
                        }
                    }
                    .text {
                        width: 60%;
                        padding: 2%;
                        .title {
                            font-weight: 500;
                            font-size: 22px;
                        }
                        .detail {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .btn {
            text-decoration: none;
            text-align: center;
            padding: 10px;
            margin-bottom: 15px;
            margin-top: 35px;
            border-radius: 20px;
            color: black;
            // background-color: #ecf8f8;
            background-color: #c3ac9a8f;
            box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
            -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
            transition: 0.5s;
        }
        .btn:hover {
            color: black;
            right: 2%;
            box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
            -webkit-box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
        }
        .intro {
            width: 80%;
            display: flex;
            align-items: center;
            flex-direction: column;
            padding-top: 90px;
            h1 {
                font-family: "Alegreya", serif !important;
                font-size: 56px;
                text-align: center;
                margin-bottom: 2%;
                font-style: italic;
                color: #585555;
            }
            .text {
                font-size: 18px;
                font-weight: 300;
                text-align: center;
                padding: 1%;
            }
        }
        h2 {
            // text-align: center;
            // font-family: "Sukar Regular";
            margin: 3%;
        }
        .contactBox {
            display: flex;
            margin: 1vw;
            padding: 10px;
            .mailSection {
                width: 25%;
                display: flex;
                flex-direction: column;
                padding: 5%;
                background-color: $secondary_color;
                color: white;
                span {
                }
                a {
                    text-decoration: none;
                    color: white;
                }
            }
            .contactForm {
                width: 85%;
                padding: 25px;
                // background-color: #6b705c;
                // background-color: #dfe0da;
                background-color: #b3bf8661;
                form {
                    input {
                        background: transparent;
                        // border: 1px solid white;
                        border: 1px solid;
                        padding: 2%;
                        width: 100%;
                        min-height: 56px;
                        transition: 0.5s;
                        // color: white;
                        color: black;
                    }
                    input::placeholder {
                        // color: white;
                    }
                    input:focus {
                        outline: none;
                        border: 1px solid black;
                        background: #ffe8d6;
                        color: black;
                        border-bottom: 1.3px solid;
                        border-left: 1.3px solid;
                        border-radius: 5px;
                    }
                    input:focus::placeholder {
                        color: black;
                    }
                    input#check {
                        width: 20px;
                    }
                    label {
                        a {
                            color: black !important;
                            text-decoration: underline;
                        }
                    }
                    .date {
                        position: relative;
                        input[type="date"] {
                            border: 1px solid;
                            border-radius: 0;
                            // all: unset;
                            // all: initial;
                        }
                        input[type="date"]/* ::-webkit-calendar-picker-indicator  */ {
                            // color: rgba(0, 0, 0, 0);
                            opacity: 1;
                            // display: block;
                            // background: url(https://mywildalberta.ca/images/GFX-MWA-Parks-Reservations.png) no-repeat;
                            // width: 20px;
                            // height: 20px;
                            // border-width: thin;
                        }
                        label {
                            position: absolute;
                            background-color: #e2d9b8;
                            top: 28%;
                            left: 20px;
                            height: 70%;
                            width: 40%;
                        }
                    }

                    input[type="date"]:focus {
                        // color: rgba(0, 0, 0, 0);
                        opacity: 1;
                        background: #ffe8d6;
                        color: black;
                        border-bottom: 1.3px solid;
                        border-left: 1.3px solid;
                        border-radius: 5px;
                        box-shadow: none;
                        // display: block;
                        // background: url(https://mywildalberta.ca/images/GFX-MWA-Parks-Reservations.png) no-repeat;
                        // width: 20px;
                        // height: 20px;
                        // border-width: thin;
                    }
                    .ant-picker {
                        background-color: #e2d9b8 !important;
                        border: 1px solid;
                        .ant-picker-input {
                            input {
                                border: none !important;
                            }
                            input::placeholder {
                                color: black !important;
                            }
                        }
                    }
                    select {
                        background: transparent;
                        // border: 1px solid white;
                        border: 1px solid;
                        // color: white;
                        padding: 2%;
                        width: 100%;
                        min-height: 56px;
                        border-radius: 0;
                    }
                    select:focus {
                        outline: none;
                        border: 1px solid black;
                        color: black;
                        background: #ffe8d6;
                        border-bottom: 1.3px solid;
                        border-left: 1.3px solid;
                        border-radius: 5px;
                        box-shadow: none;
                    }

                    button {
                        border-radius: 20px;
                        color: black;
                        // background-color: #ecf8f8;
                        background-color: #c3ac9a8f;
                        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
                        -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
                        -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
                        transition: 0.5s;
                        width: 26%;
                    }
                    button:hover {
                        color: black;
                        // right: 15.5%;
                        // right: 2%;
                        box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
                        -webkit-box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
                        -moz-box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
                    }
                }
            }
            .stripedLotus {
                position: relative;
                border-radius: 2px;
                width: 15%;
                margin-left: 1vw;
                // background-color: #6b705c;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                .circle {
                    text-align: center;
                    width: 10vw;
                    height: 10vw;
                    border-radius: 50%;
                    // background-color: $primary_color;
                    background-color: #6b705c;
                    margin-bottom: 10px;

                    -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
                    -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
                    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
                    img {
                        width: 100%;
                    }
                }
                .circle.top {
                    position: absolute;
                    top: 10px;
                }
            }
            .image {
                width: 25%;
                img {
                    width: 100%;
                }
            }
        }

        .blogs {
            width: 80%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 2%;
            .ant-ribbon-wrapper {
                width: 100% !important;
            }
            .blogCard {
                display: flex;
                border: 1px solid rgb(1, 138, 119);
                margin: 1%;
                background-color: $secondary_color;
                color: white;
                min-width: 100%;
                height: 30vw;
                position: relative;
                .pinTag{
                    border-radius: 50%;
                    position: absolute;
                    right: 2px;
                    top: 2px;
                }
                .image {
                    width: 30%;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .details {
                    width: 70%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    padding: 2%;
                    .topSection {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        .left {
                            display: flex;
                            .pfp {
                                margin-right: 5px;
                                svg {
                                }
                            }
                            .shortDetails {
                                display: flex;
                                flex-direction: column;
                                .userName {
                                    font-size: 14px;
                                }
                                .timeDetails {
                                    display: flex;
                                    flex-direction: row;
                                    font-size: 12px;
                                    .date {
                                    }
                                    .postedAgo {
                                    }
                                }
                            }
                        }
                        .right {
                            .moreButton {
                                cursor: pointer;
                            }
                        }
                    }
                    .centerSection {
                        display: flex;
                        flex-direction: column;
                        height: 88%;
                        overflow: hidden;
                        .blogTitle {
                            font-family: "Playfair Display", serif !important;
                            height: 8%;
                        }
                        .shortBrief {
                            font-size: 16px;
                            font-weight: 300;
                            padding: 1%;
                            height: 92%;
                        }
                    }
                    .bottomSection {
                        height: 12%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        border-top: 1px solid;
                        align-items: center;
                        font-size: 12px;
                        padding-top: 3%;
                        .left {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-around;
                            .date {
                                margin-right: 3px;
                            }
                        }
                        .right {
                            display: flex;
                            align-items: center;
                            .likeCount {
                                margin-right: 3px;
                                cursor: pointer;
                                text-decoration: none;
                                background-color: wheat;
                                color: black;
                                padding: 1.2px;
                                border-radius: 10px;
                            }
                            .like {
                                svg {
                                    fill: red;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
            .singleBlogPost {
                display: flex;
                flex-wrap: wrap;
                border: 1px solid rgb(1, 138, 119);
                flex-direction: column;
                align-items: center;
                width: 100%;
                .image {
                    width: 100%;
                    // max-height: 50vw;
                    position: relative;
                    overflow: hidden;
                    img {
                        width: 100%;
                    }
                    .gradient {
                        display: none;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(
                            180deg,
                            rgba(2, 0, 36, 0) 0%,
                            rgba(0, 0, 0, 0.5494572829131652) 59%,
                            rgba(0, 0, 0, 1) 100%
                        );
                        position: absolute;
                        bottom: 0;
                        z-index: 1;
                    }

                    .title {
                        font-family: "Alegreya", serif;

                        font-weight: 100;
                        font-size: 2rem;
                        text-align: left;
                        padding: 2%;
                        color: white;
                        position: absolute;
                        bottom: 0;
                        z-index: 2;
                        background-color: #00000094;
                        margin: 5px;
                        border-radius: 30px 0px;
                    }
                }
                .dateAndBy {
                    padding: 1%;
                    text-align: left;
                    width: 100%;

                    span {
                        font-style: italic;
                    }
                }
                .blogPost {
                    padding: 1%;
                    width: 98%;
                    border-top: 1px solid;
                    div {
                        p {
                            strong {
                                // color: red !important;
                                font-weight: 600;
                            }
                        }
                        // font-family: Arial, sans-serif;
                        // color: red;
                    }
                }
            }
        }
        .quadrant {
            width: 100%;
            background-color: $primary_color;
            display: flex;
            flex-direction: column;
            position: relative;
            height: 100vh;
            margin: 0px;
            .top {
                display: flex;
                height: 50%;
                .topLeft {
                    width: 50%;
                    height: 100%;
                    text-align: center;
                    position: relative;
                    cursor: pointer;
                    background-color: #ffe8d6;
                    text-decoration: none;
                    color: #6b705c;
                    overflow: hidden;
                    h1 {
                        font-family: "Alegreya", serif;
                        font-size: 5.2vw;
                        position: absolute;
                        transform: translateY(-50%);
                        top: 50%;
                        font-style: italic;
                        width: 100%;
                        color: #585555;
                    }

                    .text {
                    }
                    .image {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        img {
                        }
                    }
                    img {
                        width: 25%;
                        position: absolute;
                        bottom: -23%;
                        right: -12%;
                    }
                }
                .topLeft:hover {
                    opacity: 0.6;
                }
                .topLeft.boxx.active {
                    width: 100%;
                    height: 100vh;
                }
                .topLeft.boxx.none {
                    display: none;
                }
                .topRight {
                    width: 50%;
                    position: relative;
                    cursor: pointer;
                    height: 100%;
                    text-align: center;
                    text-decoration: none;
                    color: white;
                    background-color: #6b705c;
                    overflow: hidden;

                    img {
                        width: 25%;
                        position: absolute;
                        bottom: -23%;
                        left: -12%;
                    }
                    h1 {
                        font-size: 5.2vw;
                        position: absolute;
                        transform: translateY(-50%);
                        top: 50%;
                        font-style: italic;
                        width: 100%;
                        color: #585555;
                    }
                    .text {
                    }
                    .image {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        img {
                        }
                    }
                }
                .topRight:hover {
                    opacity: 0.9;
                }
                .topRight.boxx.active {
                    width: 100%;
                    height: 100vh;
                }
                .topRight.boxx.none {
                    display: none;
                }
            }
            .bottom {
                display: flex;
                height: 50%;

                .bottomRight {
                    width: 50%;
                    height: 100%;
                    position: relative;
                    cursor: pointer;
                    text-align: center;
                    color: #6b705c;
                    background-color: #ffe8d6;
                    text-decoration: none;
                    overflow: hidden;

                    img {
                        width: 25%;
                        position: absolute;
                        top: -23%;
                        right: -12%;
                    }
                    h1 {
                        font-family: "Alegreya", serif;
                        font-size: 5.2vw;
                        position: absolute;
                        transform: translateY(-50%);
                        top: 50%;
                        font-style: italic;
                        width: 100%;
                        color: #585555;
                    }
                    .text {
                    }
                    .image {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        img {
                        }
                    }
                }
                .bottomRight:hover {
                    opacity: 0.9;
                }
                .bottomRight.boxx.active {
                    width: 100%;
                    height: 100vh;
                }
                .bottomRight.boxx.none {
                    display: none;
                }
                .bottomLeft {
                    width: 50%;
                    height: 100%;
                    position: relative;
                    cursor: pointer;
                    text-align: center;
                    text-decoration: none;
                    color: white;
                    background-color: #6b705c;
                    overflow: hidden;

                    img {
                        width: 25.5%;
                        position: absolute;
                        top: -23.6%;
                        left: -12.7%;
                    }
                    h1 {
                        font-family: "Alegreya", serif;
                        font-size: 5.2vw;
                        position: absolute;
                        transform: translateY(-50%);
                        top: 50%;
                        font-style: italic;
                        width: 100%;
                        color: #585555;
                    }
                    .text {
                    }
                    .image {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                    }
                }
                .bottomLeft:hover {
                    opacity: 0.9;
                }
                .bottomLeft.boxx.active {
                    width: 100%;
                    height: 100vh;
                }
                .bottomLeft.boxx.none {
                    display: none;
                }
            }
            .centerImage {
                width: 13%;
                // height: 100px;
                border-radius: 50px;
                // background-color: coral;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: 1s;
                animation: rotate 1.4s;

                // overflow: hidden;
                // transform: skew(20deg);
                // img {
                //     transform: translate(0%, -23%) rotate(360deg);
                //     width: 100%;
                // }
            }
            // .rotate{
            //     transform:rotate(360deg)
            // }
            a.blogLink {
                position: absolute;
                bottom: 2%;
                left: 50%;
                transform: translateX(-50%);
                background-color: #ffffff;
                padding: 10px;
                border-radius: 25px;
                text-decoration: none;
                color: black;
            }
            a:hover.blogLink {
                box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
                -webkit-box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
                -moz-box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
            }
        }
    }
    .footer {
        width: 100%;
        background-color: white;
        padding: 2% 8% 2% 8%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        span {
        }
        a {
            text-decoration: none;
            color: black;
        }
    }
    .cornerArrowTL {
        position: fixed;
        cursor: pointer;
        z-index: 100;
        width: 8%;
        top: 0vh;
        left: 0vw;
        text-align: center;
        text-decoration: none;
        color: black;
        // color: rgb(255, 255, 255);
        font-weight: 600;
        // filter: invert(100%);
        // color: black;
        // font-size: 75px;
        // text-decoration: none;

        // transform: rotate(180deg);
        img {
            width: 100%;
            animation: cornerRotate 2.4s infinite linear;
            border-radius: 50%;
        }
        img:hover {
            // box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
            // -webkit-box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
            // -moz-box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
        }
    }
    .cornerArrowTR {
        position: fixed;
        cursor: pointer;
        z-index: 100;
        width: 8%;
        top: 0vh;
        right: 0vw;
        text-align: center;
        text-decoration: none;
        color: black;
        font-weight: 600;

        // transform: rotate(88deg);
        img {
            width: 100%;
            animation: cornerRotate 2.4s infinite linear;
            border-radius: 50%;
        }
        img:hover {
            // box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
            // -webkit-box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
            // -moz-box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
        }
    }
    .cornerArrowBR {
        position: fixed;
        cursor: pointer;
        z-index: 100;
        width: 8%;
        bottom: 0;
        right: 0vw;
        color: black;
        font-weight: 600;
        text-align: center;
        text-decoration: none;

        // transform: rotate(180deg);
        img {
            width: 100%;
            animation: cornerRotate 2.4s infinite linear;
            border-radius: 50%;
        }
        img:hover {
            // box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
            // -webkit-box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
            // -moz-box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
        }
    }
    .cornerArrowBL {
        position: fixed;
        cursor: pointer;
        z-index: 100;
        width: 8%;
        bottom: 0;
        left: 0vw;
        text-decoration: none;
        color: black;
        text-align: center;
        font-weight: 600;
        // color: black;
        // font-size: 75px;
        // font-weight: 600;
        img {
            width: 100%;
            animation: cornerRotate 2.4s infinite linear;
            border-radius: 50%;
        }
        img:hover {
            // box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
            // -webkit-box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
            // -moz-box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
        }
    }
}
@media (min-width: 320px) and (max-width: 768px) {
    // @media (max-width: 320px) and (min-width: 768px) {
    .mainWrapper {
        .contentContainer {
            .contactBox {
                .stripedLotus {
                    display: none;
                }
            }
        }
    }
}
@media (min-width: 320px) and (max-width: 768px) {
    // @media (max-width: 320px) and (min-width: 768px) {
    .mainWrapper {
        h1 {
            font-size: 50px;
        }
        .corner {
            width: 10% !important;
        }
        .cornerArrowBL {
        }
        .cornerArrowBR {
        }
        .cornerArrowTL {
            img {
                width: 150%;
            }
        }
        .cornerArrowTR {
        }
        .quadrant {
            .centerImage {
                width: 30%;
            }
        }
        .contentContainer {
            .blogs {
                width: 100%;
            }
            .cAR {
                font-size: 35px;
            }
            .readingsPage {
                text-align: center;
                .readings {
                    text-align: center;
                }
                .cards {
                    height: unset;
                    .cardd {
                        margin: 7px 0.5%;
                        .image {
                            img {
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .intro {
                h1 {
                    font-size: 35px;
                }
            }
            .myStory {
                .stripedLotus {
                    display: none;
                }
                .content {
                    width: unset;
                    padding: unset;
                    text-align: center !important;
                    h1 {
                        font-size: 30px;
                    }
                }
            }
            .hero {
                padding: unset;
                .sub {
                    .textBox {
                        padding: 15px;
                    }
                }
            }
            .testimonies {
                h1 {
                    font-size: 35px;
                }
                .testimony {
                    flex-direction: column;
                    padding: 15px;
                    .image {
                        width: 50%;
                    }
                    .text {
                        text-align: center !important;
                        .content {
                            text-align: center !important;
                        }
                    }
                }
                .testimony.animate__fadeInLeftBig {
                    flex-direction: column-reverse;
                }
            }
            .contactBox {
                .stripedLotus {
                    display: none;
                }
                .contactForm {
                    form {
                        button {
                            width: unset;
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .mainWrapper {
        .topBar {
            .title {
            }
            .navBar {
                width: 60%;
                span {
                }
            }
        }
        .contentContainer {
            .readingsPage {
                .cards {
                    height: unset;
                }
            }
            .hero {
                .sub {
                    .image {
                        img {
                        }
                    }
                    .textBox {
                        h1 {
                            font-style: italic;
                        }
                        p {
                        }
                    }
                }
            }
            .testimonies {
                .testimony {
                    .image {
                        img {
                        }
                    }
                    .text {
                    }
                    .author {
                    }
                }
            }
            .contactSection {
                .left {
                    span {
                    }
                    a {
                    }
                }
                .right {
                    form {
                        input {
                        }
                        input:focus {
                        }
                        button {
                        }
                    }
                }
            }
        }
        .footer {
            span {
            }
            a {
            }
        }
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .mainWrapper {
        .topBar {
            .title {
            }
            .navBar {
                span {
                }
            }
        }
        .contentContainer {
            .hero {
                .sub {
                    .image {
                        img {
                        }
                    }
                    .textBox {
                        h1 {
                            font-style: italic;
                        }
                        p {
                        }
                    }
                }
            }
            .testimonies {
                flex-wrap: wrap;
                .testimony {
                    width: 100%;
                    .image {
                        img {
                        }
                    }
                    .text {
                    }
                    .author {
                    }
                }
            }
            .contactSection {
                .left {
                    span {
                    }
                    a {
                    }
                }
                .right {
                    form {
                        input {
                        }
                        input:focus {
                        }
                        button {
                        }
                    }
                }
            }
        }
        .footer {
            span {
            }
            a {
            }
        }
    }
}

@media (min-width: 320px) and (max-width: 1075px) {
    .mainWrapper {
        .topBar {
            .title {
            }
            .navBar {
                width: 100%;
                span {
                }
            }
        }
        .contentContainer {
            .readingsPage {
                display: flex;
                flex-direction: column;
                .readings {
                    .cards {
                        .cardd {
                            .text {
                                .detail {
                                    font-size: 20px;
                                }
                            }
                        }
                    }
                }
            }
            .hero {
                width: 100%;
                .sub {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    height: unset;
                    align-items: center;
                    .image {
                        position: unset;
                        width: 80%;
                        text-align: center;
                        img {
                            border-radius: 50%;
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .textBox {
                        width: 100%;
                        position: unset;
                        transform: unset;
                        max-width: unset;
                        margin-top: 10px;
                        // border: 8px solid $primary_color;
                        h1 {
                            font-family: "Alegreya", serif !important;
                            font-size: 56px;
                            text-align: center;
                            margin-bottom: 2%;
                            font-style: italic;
                        }
                        p {
                            font-size: 20px;
                        }
                    }
                    a.dyr {
                        margin-left: unset;
                        width: unset;
                        position: unset;
                        transform: unset;
                    }
                }
            }
            .testimonies {
                flex-wrap: wrap;
                .testimony {
                    width: 100%;
                    .image {
                        img {
                        }
                    }
                    .text {
                    }
                    .author {
                    }
                }
            }
            .contactSection {
                display: flex;
                flex-direction: column;
                .left {
                    span {
                    }
                    a {
                    }
                }
                .right {
                    width: 100%;
                    form {
                        input {
                        }
                        input:focus {
                        }
                        button {
                        }
                    }
                }
            }
            .broucher {
                .title {
                    font-size: 5vw;
                }
            }
            .cards {
                .cardd {
                    width: 100%;
                    margin-left: unset !important;
                    .text {
                        .detail {
                            font-size: unset;
                        }
                    }
                }
            }
            .contactBox {
                // flex-direction: column;
                .mailSection {
                    width: 100%;
                }
                .contactForm {
                    width: 100%;
                }
                .image {
                    display: none;
                }
            }
        }
        .footer {
            display: flex;
            flex-direction: column;
            align-items: center;
            span {
                margin: 10px 0;
            }
            a {
            }
        }
    }
}
@media (min-width: 320px) and (max-width: 420px) {
    .mainWrapper {
        .topBar {
            .title {
            }
            .navBar {
                font-size: 3.5vw;
                span {
                    // font-size: ;
                }
            }
        }
        .contentContainer {
        }
    }
}
@keyframes rotate {
    from {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(720deg);
    }
}
@keyframes cornerRotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@media (min-width: 1400px) and (max-width: 2000px) {
    .mainWrapper {
        .contentContainer {
            .testimonies {
                margin-top: 65px;
            }
        }
    }
}
@media (min-width: 2000px) and (max-width: 2500px) {
    .mainWrapper {
        .contentContainer {
            .testimonies {
                margin-top: 10%;
            }
        }
    }
}
@media (min-width: 320px) and (max-width: 600px) {
    .mainWrapper {
        .contentContainer {
            .blogs {
                width: 90%;
                .blogCard {
                    .details {
                        .centerSection {
                            display: flex;
                            flex-direction: column;
                            .blogTitle {
                                font-family: "Playfair Display", serif !important;
                                font-size: 3vw;
                            }
                            .shortBrief {
                                font-size: 2.3vw;
                            }
                        }
                        .bottomSection {
                            .right {
                                .likeCount {
                                    font-size: 1.7vw;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

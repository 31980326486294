@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital@0;1&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,700;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@1,400;1,500&display=swap");
@font-face {
  font-family: "Sukar Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Sukar Regular"), url("../fonts/SukarRegular.woff") format("woff");
}

.mainWrapper {
  font-family: "Sukar Regular", sans-serif;
  font-weight: 100;
  background-color: #ffe8d6;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  -webkit-transition: 2s;
  transition: 2s;
  -webkit-animation: scalee 1.5s;
          animation: scalee 1.5s;
}

@-webkit-keyframes scalee {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scalee {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.mainWrapper .quadrant {
  width: 100%;
  background-color: #ffe8d6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  height: 100vh;
  margin: 0px;
}

.mainWrapper .quadrant .top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 50%;
}

.mainWrapper .quadrant .top .topLeft {
  width: 50%;
  height: 100%;
  text-align: center;
  position: relative;
  cursor: pointer;
  background-color: #ffe8d6;
  text-decoration: none;
  color: #6b705c;
  overflow: hidden;
}

.mainWrapper .quadrant .top .topLeft h1 {
  font-family: "Alegreya", serif;
  font-size: 5.2vw;
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  top: 50%;
  font-style: italic;
  width: 100%;
  color: gray;
}

.mainWrapper .quadrant .top .topLeft .image {
  position: absolute;
  bottom: 0;
  right: 0;
}

.mainWrapper .quadrant .top .topLeft img {
  width: 25%;
  position: absolute;
  bottom: -23%;
  right: -12%;
}

.mainWrapper .quadrant .top .topLeft:hover {
  opacity: 0.6;
}

.mainWrapper .quadrant .top .topLeft.boxx.active {
  width: 100%;
  height: 100vh;
}

.mainWrapper .quadrant .top .topLeft.boxx.none {
  display: none;
}

.mainWrapper .quadrant .top .topRight {
  width: 50%;
  position: relative;
  cursor: pointer;
  height: 100%;
  text-align: center;
  text-decoration: none;
  color: white;
  background-color: #6b705c;
  overflow: hidden;
}

.mainWrapper .quadrant .top .topRight img {
  width: 25%;
  position: absolute;
  bottom: -23%;
  left: -12%;
}

.mainWrapper .quadrant .top .topRight h1 {
  font-family: "Alegreya", serif;
  font-size: 5.2vw;
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  top: 50%;
  font-style: italic;
  width: 100%;
  color: wheat;
}

.mainWrapper .quadrant .top .topRight .image {
  position: absolute;
  bottom: 0;
  left: 0;
}

.mainWrapper .quadrant .top .topRight:hover {
  opacity: 0.9;
}

.mainWrapper .quadrant .top .topRight.boxx.active {
  width: 100%;
  height: 100vh;
}

.mainWrapper .quadrant .top .topRight.boxx.none {
  display: none;
}

.mainWrapper .quadrant .bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 50%;
}

.mainWrapper .quadrant .bottom .bottomRight {
  width: 50%;
  height: 100%;
  position: relative;
  cursor: pointer;
  text-align: center;
  color: #6b705c;
  background-color: #ffe8d6;
  text-decoration: none;
  overflow: hidden;
}

.mainWrapper .quadrant .bottom .bottomRight img {
  width: 25%;
  position: absolute;
  top: -23%;
  right: -12%;
}

.mainWrapper .quadrant .bottom .bottomRight h1 {
  font-family: "Alegreya", serif;
  font-size: 5.2vw;
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  top: 50%;
  font-style: italic;
  width: 100%;
  color: gray;
}

.mainWrapper .quadrant .bottom .bottomRight .image {
  position: absolute;
  bottom: 0;
  left: 0;
}

.mainWrapper .quadrant .bottom .bottomRight:hover {
  opacity: 0.6;
}

.mainWrapper .quadrant .bottom .bottomRight.boxx.active {
  width: 100%;
  height: 100vh;
}

.mainWrapper .quadrant .bottom .bottomRight.boxx.none {
  display: none;
}

.mainWrapper .quadrant .bottom .bottomLeft {
  width: 50%;
  height: 100%;
  position: relative;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  color: white;
  background-color: #6b705c;
  overflow: hidden;
}

.mainWrapper .quadrant .bottom .bottomLeft img {
  width: 25.5%;
  position: absolute;
  top: -23.6%;
  left: -12.7%;
}

.mainWrapper .quadrant .bottom .bottomLeft h1 {
  font-family: "Alegreya", serif;
  font-size: 5.2vw;
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  top: 50%;
  font-style: italic;
  width: 100%;
  color: wheat;
}

.mainWrapper .quadrant .bottom .bottomLeft .image {
  position: absolute;
  bottom: 0;
  right: 0;
}

.mainWrapper .quadrant .bottom .bottomLeft:hover {
  opacity: 0.9;
}

.mainWrapper .quadrant .bottom .bottomLeft.boxx.active {
  width: 100%;
  height: 100vh;
}

.mainWrapper .quadrant .bottom .bottomLeft.boxx.none {
  display: none;
}

.mainWrapper .quadrant .centerImage {
  width: 13%;
  border-radius: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: 1s;
  transition: 1s;
  -webkit-animation: rotate 2.8s;
          animation: rotate 2.8s;
}

.mainWrapper .quadrant a.blogLink {
  position: absolute;
  bottom: 2%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: #ffffff;
  padding: 10px;
  border-radius: 25px;
  text-decoration: none;
  color: black;
  text-align: center;
}

.mainWrapper .quadrant a:hover.blogLink {
  box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
}

.mainWrapper .topBar {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 40px;
}

.mainWrapper .topBar .title {
  font-family: "Alegreya", serif;
  font-style: italic;
  font-size: 55px;
  border-bottom: 1px solid;
  width: 80%;
  text-align: center;
  padding-bottom: 30px;
}

.mainWrapper .topBar .wheelOfFortune {
  text-align: center;
}

.mainWrapper .topBar .wheelOfFortune img {
  width: 30%;
  border-radius: 50%;
  -webkit-animation: wheel 2s linear infinite;
          animation: wheel 2s linear infinite;
}

@-webkit-keyframes wheel {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes wheel {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.mainWrapper .topBar .wheelOfFortune img:hover {
  cursor: pointer;
}

.mainWrapper .topBar .navBar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 45%;
  font-size: 14px;
  font-weight: 300;
  padding: 10px;
}

.mainWrapper .topBar .navBar a {
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.mainWrapper .contentContainer {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  padding: 0% 7%;
}

.mainWrapper .contentContainer img.myStoryBg {
  position: absolute;
  width: 100%;
  height: 100vw;
  opacity: 0.3;
  pointer-events: none;
}

.mainWrapper .contentContainer .hero {
  position: relative;
  width: 100%;
  padding: 75px 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mainWrapper .contentContainer .hero .sub {
  width: 100%;
  position: relative;
  height: 785px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.mainWrapper .contentContainer .hero .sub .image {
  position: absolute;
  top: 35%;
  right: 0%;
  width: 40%;
  text-align: center;
}

.mainWrapper .contentContainer .hero .sub .image img {
  width: 80%;
}

.mainWrapper .contentContainer .hero .sub .textBox {
  width: 100%;
  min-height: 300px;
  top: 0;
  padding: 10px 40% 10px 10px;
  background-color: #6b705c;
  color: white;
}

.mainWrapper .contentContainer .hero .sub .textBox h1 {
  font-family: "Alegreya", serif !important;
  font-size: 56px;
  text-align: center;
  margin-bottom: 2%;
  font-style: italic;
  color: cornsilk;
}

.mainWrapper .contentContainer .hero .sub a.dyr {
  width: 20%;
  margin-left: 20%;
  text-decoration: none;
  text-align: center;
  border-radius: 20px;
  color: black;
  background-color: #c3ac9a8f;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.mainWrapper .contentContainer .hero .sub a.dyr:hover {
  color: black;
  right: 15.5%;
  box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
}

.mainWrapper .contentContainer a.dyr {
  text-decoration: none;
  text-align: center;
  padding: 10px;
  margin-top: 15px;
  border-radius: 20px;
  color: black;
  background-color: #c3ac9a8f;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.mainWrapper .contentContainer a.dyr:hover {
  color: black;
  right: 2%;
  box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
}

.mainWrapper .contentContainer .testimonies {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  margin-top: -65px;
  padding: 15px;
}

.mainWrapper .contentContainer .testimonies h1 {
  width: 100%;
  font-family: "Alegreya", serif !important;
  font-size: 56px;
  text-align: center;
  margin-bottom: 2%;
  font-style: italic;
  color: #585555;
}

.mainWrapper .contentContainer .testimonies .testimony {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 15px;
  position: relative;
  background-color: #6b705c;
  padding: 30px;
}

.mainWrapper .contentContainer .testimonies .testimony .image {
  width: 20%;
}

.mainWrapper .contentContainer .testimonies .testimony .image img {
  width: 100%;
  border-radius: 50%;
}

.mainWrapper .contentContainer .testimonies .testimony .text {
  width: 80%;
  color: white;
  text-align: right;
  font-weight: 300;
  padding: 10px;
}

.mainWrapper .contentContainer .testimonies .testimony .text .author {
  font-size: 1.2rem;
  margin-top: 10px;
}

.mainWrapper .contentContainer .contactSection {
  width: 80%;
  padding: 2%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 60px;
}

.mainWrapper .contentContainer .contactSection .left {
  width: 40%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.mainWrapper .contentContainer .contactSection .left span {
  font-family: "Alegreya", serif;
  font-size: 2rem;
}

.mainWrapper .contentContainer .contactSection .left a {
  padding-top: 1%;
  text-decoration: none;
  color: black;
}

.mainWrapper .contentContainer .contactSection .right {
  width: 48%;
}

.mainWrapper .contentContainer .contactSection .right form input {
  background: transparent;
  border: 1px solid;
  padding: 2%;
  width: 100%;
  min-height: 56px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.mainWrapper .contentContainer .contactSection .right form input:focus {
  outline: none;
}

.mainWrapper .contentContainer .contactSection .right form button {
  border-radius: 20px;
  color: black;
  background-color: #c3ac9a8f;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 26%;
}

.mainWrapper .contentContainer .contactSection .right form button:hover {
  color: black;
  box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
}

.mainWrapper .contentContainer .broucher {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: white;
  width: 100%;
}

.mainWrapper .contentContainer .broucher .image {
  width: 50%;
}

.mainWrapper .contentContainer .broucher .image img {
  width: 100%;
}

.mainWrapper .contentContainer .broucher .title {
  width: 50%;
  font-family: "Alegreya", serif;
  font-style: italic;
  font-size: 55px;
  text-align: center;
}

.mainWrapper .contentContainer .myStory {
  width: 92%;
  padding: 10% 15px 10px 15px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.mainWrapper .contentContainer .myStory .stripedLotus {
  position: relative;
  border-radius: 2px;
  width: 15%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mainWrapper .contentContainer .myStory .stripedLotus .circle {
  text-align: center;
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  background-color: #ffe8d6;
  margin-bottom: 10px;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
}

.mainWrapper .contentContainer .myStory .stripedLotus .circle img {
  width: 100%;
}

.mainWrapper .contentContainer .myStory .stripedLotus .circle.top {
  position: absolute;
  top: 10px;
}

.mainWrapper .contentContainer .myStory .content {
  width: 85%;
  padding: 40px;
}

.mainWrapper .contentContainer .myStory .content h1 {
  font-family: "Alegreya", serif !important;
  font-size: 56px;
  text-align: center;
  margin-bottom: 2%;
  font-style: italic;
  color: #585555;
}

.mainWrapper .contentContainer .myStory .content p {
  padding: 1%;
  font-size: 16px;
  font-weight: 300;
}

.mainWrapper .contentContainer .codeOfEthics {
  width: 70%;
  padding: 12px;
}

.mainWrapper .contentContainer .codeOfEthics h3 {
  font-family: "Alegreya", serif !important;
}

.mainWrapper .contentContainer .codeOfEthics h1 {
  font-family: "Alegreya", serif !important;
  font-size: 40px;
  font-style: italic;
  color: #585555;
}

.mainWrapper .contentContainer .codeOfEthics span {
  margin-bottom: 10px;
}

.mainWrapper .contentContainer .codeOfEthics ul li {
  padding: 1%;
  font-size: 18px;
  font-weight: 300;
}

.mainWrapper .contentContainer .disclaimer {
  width: 70%;
  padding: 12px;
}

.mainWrapper .contentContainer .disclaimer h3 {
  font-family: "Alegreya", serif !important;
}

.mainWrapper .contentContainer .disclaimer .text {
  padding: 2%;
}

.mainWrapper .contentContainer .disclaimer .text p {
  font-size: 18px;
  font-weight: 300;
}

.mainWrapper .contentContainer .cAR {
  font-family: "Alegreya", serif !important;
  font-size: 56px;
  text-align: center;
  font-style: italic;
  margin-top: 65px;
  margin-top: 50px;
  color: #585555;
}

.mainWrapper .contentContainer .readingsPage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
}

.mainWrapper .contentContainer .readingsPage .readings {
  width: 80%;
  padding-top: 50px;
  text-align: center;
}

.mainWrapper .contentContainer .readingsPage .readings .cAR {
  font-family: "Alegreya", serif !important;
  font-size: 56px;
  text-align: center;
  font-style: italic;
  margin-top: 65px;
  margin-top: 50px;
  color: #585555;
  margin-bottom: 90px;
}

.mainWrapper .contentContainer .readingsPage .readings p {
  padding: 0% 1%;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
}

.mainWrapper .contentContainer .readingsPage h3 {
  font-family: "Alegreya", serif !important;
}

.mainWrapper .contentContainer .readingsPage h1 {
  font-family: "Alegreya", serif !important;
  font-size: 56px;
  text-align: center;
  font-style: italic;
  color: #585555;
}

.mainWrapper .contentContainer .readingsPage .cards::-webkit-scrollbar {
  display: none;
}

.mainWrapper .contentContainer .readingsPage .cards {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.mainWrapper .contentContainer .readingsPage .cards {
  width: 100%;
  height: 700px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow-y: scroll;
}

.mainWrapper .contentContainer .readingsPage .cards h1 {
  font-family: "Alegreya", serif !important;
  font-size: 56px;
  text-align: center;
  font-style: italic;
  color: #585555;
}

.mainWrapper .contentContainer .readingsPage .cards h3 {
  font-family: "Alegreya", serif !important;
  font-size: 56px;
  text-align: center;
  margin-bottom: 2%;
  font-style: italic;
}

.mainWrapper .contentContainer .readingsPage .cards .cardd {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 2% 5%;
  margin: 0.5%;
  width: 100%;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  background-color: #6b705c;
  color: white;
}

.mainWrapper .contentContainer .readingsPage .cards .cardd .image {
  width: 40%;
}

.mainWrapper .contentContainer .readingsPage .cards .cardd .image img {
  width: 70%;
}

.mainWrapper .contentContainer .readingsPage .cards .cardd .text {
  width: 60%;
  padding: 2%;
}

.mainWrapper .contentContainer .readingsPage .cards .cardd .text .title {
  font-weight: 500;
  font-size: 22px;
}

.mainWrapper .contentContainer .readingsPage .cards .cardd .text .detail {
  font-size: 14px;
}

.mainWrapper .contentContainer .btn {
  text-decoration: none;
  text-align: center;
  padding: 10px;
  margin-bottom: 15px;
  margin-top: 35px;
  border-radius: 20px;
  color: black;
  background-color: #c3ac9a8f;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.mainWrapper .contentContainer .btn:hover {
  color: black;
  right: 2%;
  box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
}

.mainWrapper .contentContainer .intro {
  width: 80%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-top: 90px;
}

.mainWrapper .contentContainer .intro h1 {
  font-family: "Alegreya", serif !important;
  font-size: 56px;
  text-align: center;
  margin-bottom: 2%;
  font-style: italic;
  color: #585555;
}

.mainWrapper .contentContainer .intro .text {
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  padding: 1%;
}

.mainWrapper .contentContainer h2 {
  margin: 3%;
}

.mainWrapper .contentContainer .contactBox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 1vw;
  padding: 10px;
}

.mainWrapper .contentContainer .contactBox .mailSection {
  width: 25%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 5%;
  background-color: #6b705c;
  color: white;
}

.mainWrapper .contentContainer .contactBox .mailSection a {
  text-decoration: none;
  color: white;
}

.mainWrapper .contentContainer .contactBox .contactForm {
  width: 85%;
  padding: 25px;
  background-color: #b3bf8661;
}

.mainWrapper .contentContainer .contactBox .contactForm form input {
  background: transparent;
  border: 1px solid;
  padding: 2%;
  width: 100%;
  min-height: 56px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: black;
}

.mainWrapper .contentContainer .contactBox .contactForm form input:focus {
  outline: none;
  border: 1px solid black;
  background: #ffe8d6;
  color: black;
  border-bottom: 1.3px solid;
  border-left: 1.3px solid;
  border-radius: 5px;
}

.mainWrapper .contentContainer .contactBox .contactForm form input:focus::-webkit-input-placeholder {
  color: black;
}

.mainWrapper .contentContainer .contactBox .contactForm form input:focus:-ms-input-placeholder {
  color: black;
}

.mainWrapper .contentContainer .contactBox .contactForm form input:focus::-ms-input-placeholder {
  color: black;
}

.mainWrapper .contentContainer .contactBox .contactForm form input:focus::placeholder {
  color: black;
}

.mainWrapper .contentContainer .contactBox .contactForm form input#check {
  width: 20px;
}

.mainWrapper .contentContainer .contactBox .contactForm form label a {
  color: black !important;
  text-decoration: underline;
}

.mainWrapper .contentContainer .contactBox .contactForm form .date {
  position: relative;
}

.mainWrapper .contentContainer .contactBox .contactForm form .date input[type="date"] {
  border: 1px solid;
  border-radius: 0;
}

.mainWrapper .contentContainer .contactBox .contactForm form .date input[type="date"] {
  opacity: 1;
}

.mainWrapper .contentContainer .contactBox .contactForm form .date label {
  position: absolute;
  background-color: #e2d9b8;
  top: 28%;
  left: 20px;
  height: 70%;
  width: 40%;
}

.mainWrapper .contentContainer .contactBox .contactForm form input[type="date"]:focus {
  opacity: 1;
  background: #ffe8d6;
  color: black;
  border-bottom: 1.3px solid;
  border-left: 1.3px solid;
  border-radius: 5px;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.mainWrapper .contentContainer .contactBox .contactForm form .ant-picker {
  background-color: #e2d9b8 !important;
  border: 1px solid;
}

.mainWrapper .contentContainer .contactBox .contactForm form .ant-picker .ant-picker-input input {
  border: none !important;
}

.mainWrapper .contentContainer .contactBox .contactForm form .ant-picker .ant-picker-input input::-webkit-input-placeholder {
  color: black !important;
}

.mainWrapper .contentContainer .contactBox .contactForm form .ant-picker .ant-picker-input input:-ms-input-placeholder {
  color: black !important;
}

.mainWrapper .contentContainer .contactBox .contactForm form .ant-picker .ant-picker-input input::-ms-input-placeholder {
  color: black !important;
}

.mainWrapper .contentContainer .contactBox .contactForm form .ant-picker .ant-picker-input input::placeholder {
  color: black !important;
}

.mainWrapper .contentContainer .contactBox .contactForm form select {
  background: transparent;
  border: 1px solid;
  padding: 2%;
  width: 100%;
  min-height: 56px;
  border-radius: 0;
}

.mainWrapper .contentContainer .contactBox .contactForm form select:focus {
  outline: none;
  border: 1px solid black;
  color: black;
  background: #ffe8d6;
  border-bottom: 1.3px solid;
  border-left: 1.3px solid;
  border-radius: 5px;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.mainWrapper .contentContainer .contactBox .contactForm form button {
  border-radius: 20px;
  color: black;
  background-color: #c3ac9a8f;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 26%;
}

.mainWrapper .contentContainer .contactBox .contactForm form button:hover {
  color: black;
  box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.75);
}

.mainWrapper .contentContainer .contactBox .stripedLotus {
  position: relative;
  border-radius: 2px;
  width: 15%;
  margin-left: 1vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mainWrapper .contentContainer .contactBox .stripedLotus .circle {
  text-align: center;
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  background-color: #6b705c;
  margin-bottom: 10px;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
}

.mainWrapper .contentContainer .contactBox .stripedLotus .circle img {
  width: 100%;
}

.mainWrapper .contentContainer .contactBox .stripedLotus .circle.top {
  position: absolute;
  top: 10px;
}

.mainWrapper .contentContainer .contactBox .image {
  width: 25%;
}

.mainWrapper .contentContainer .contactBox .image img {
  width: 100%;
}

.mainWrapper .contentContainer .blogs {
  width: 80%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 2%;
}

.mainWrapper .contentContainer .blogs .ant-ribbon-wrapper {
  width: 100% !important;
}

.mainWrapper .contentContainer .blogs .blogCard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #018a77;
  margin: 1%;
  background-color: #6b705c;
  color: white;
  min-width: 100%;
  height: 30vw;
  position: relative;
}

.mainWrapper .contentContainer .blogs .blogCard .pinTag {
  border-radius: 50%;
  position: absolute;
  right: 2px;
  top: 2px;
}

.mainWrapper .contentContainer .blogs .blogCard .image {
  width: 30%;
}

.mainWrapper .contentContainer .blogs .blogCard .image img {
  width: 100%;
  height: 100%;
}

.mainWrapper .contentContainer .blogs .blogCard .details {
  width: 70%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  padding: 2%;
}

.mainWrapper .contentContainer .blogs .blogCard .details .topSection {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mainWrapper .contentContainer .blogs .blogCard .details .topSection .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.mainWrapper .contentContainer .blogs .blogCard .details .topSection .left .pfp {
  margin-right: 5px;
}

.mainWrapper .contentContainer .blogs .blogCard .details .topSection .left .shortDetails {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.mainWrapper .contentContainer .blogs .blogCard .details .topSection .left .shortDetails .userName {
  font-size: 14px;
}

.mainWrapper .contentContainer .blogs .blogCard .details .topSection .left .shortDetails .timeDetails {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  font-size: 12px;
}

.mainWrapper .contentContainer .blogs .blogCard .details .topSection .right .moreButton {
  cursor: pointer;
}

.mainWrapper .contentContainer .blogs .blogCard .details .centerSection {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 88%;
  overflow: hidden;
}

.mainWrapper .contentContainer .blogs .blogCard .details .centerSection .blogTitle {
  font-family: "Playfair Display", serif !important;
  height: 8%;
}

.mainWrapper .contentContainer .blogs .blogCard .details .centerSection .shortBrief {
  font-size: 16px;
  font-weight: 300;
  padding: 1%;
  height: 92%;
}

.mainWrapper .contentContainer .blogs .blogCard .details .bottomSection {
  height: 12%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-top: 1px solid;
  align-items: center;
  font-size: 12px;
  padding-top: 3%;
}

.mainWrapper .contentContainer .blogs .blogCard .details .bottomSection .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.mainWrapper .contentContainer .blogs .blogCard .details .bottomSection .left .date {
  margin-right: 3px;
}

.mainWrapper .contentContainer .blogs .blogCard .details .bottomSection .right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mainWrapper .contentContainer .blogs .blogCard .details .bottomSection .right .likeCount {
  margin-right: 3px;
  cursor: pointer;
  text-decoration: none;
  background-color: wheat;
  color: black;
  padding: 1.2px;
  border-radius: 10px;
}

.mainWrapper .contentContainer .blogs .blogCard .details .bottomSection .right .like svg {
  fill: red;
  cursor: pointer;
}

.mainWrapper .contentContainer .blogs .singleBlogPost {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  border: 1px solid #018a77;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

.mainWrapper .contentContainer .blogs .singleBlogPost .image {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.mainWrapper .contentContainer .blogs .singleBlogPost .image img {
  width: 100%;
}

.mainWrapper .contentContainer .blogs .singleBlogPost .image .gradient {
  display: none;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(2, 0, 36, 0)), color-stop(59%, rgba(0, 0, 0, 0.549457)), to(black));
  background: linear-gradient(180deg, rgba(2, 0, 36, 0) 0%, rgba(0, 0, 0, 0.549457) 59%, black 100%);
  position: absolute;
  bottom: 0;
  z-index: 1;
}

.mainWrapper .contentContainer .blogs .singleBlogPost .image .title {
  font-family: "Alegreya", serif;
  font-weight: 100;
  font-size: 2rem;
  text-align: left;
  padding: 2%;
  color: white;
  position: absolute;
  bottom: 0;
  z-index: 2;
  background-color: #00000094;
  margin: 5px;
  border-radius: 30px 0px;
}

.mainWrapper .contentContainer .blogs .singleBlogPost .dateAndBy {
  padding: 1%;
  text-align: left;
  width: 100%;
}

.mainWrapper .contentContainer .blogs .singleBlogPost .dateAndBy span {
  font-style: italic;
}

.mainWrapper .contentContainer .blogs .singleBlogPost .blogPost {
  padding: 1%;
  width: 98%;
  border-top: 1px solid;
}

.mainWrapper .contentContainer .blogs .singleBlogPost .blogPost div p strong {
  font-weight: 600;
}

.mainWrapper .contentContainer .quadrant {
  width: 100%;
  background-color: #ffe8d6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  height: 100vh;
  margin: 0px;
}

.mainWrapper .contentContainer .quadrant .top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 50%;
}

.mainWrapper .contentContainer .quadrant .top .topLeft {
  width: 50%;
  height: 100%;
  text-align: center;
  position: relative;
  cursor: pointer;
  background-color: #ffe8d6;
  text-decoration: none;
  color: #6b705c;
  overflow: hidden;
}

.mainWrapper .contentContainer .quadrant .top .topLeft h1 {
  font-family: "Alegreya", serif;
  font-size: 5.2vw;
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  top: 50%;
  font-style: italic;
  width: 100%;
  color: #585555;
}

.mainWrapper .contentContainer .quadrant .top .topLeft .image {
  position: absolute;
  bottom: 0;
  right: 0;
}

.mainWrapper .contentContainer .quadrant .top .topLeft img {
  width: 25%;
  position: absolute;
  bottom: -23%;
  right: -12%;
}

.mainWrapper .contentContainer .quadrant .top .topLeft:hover {
  opacity: 0.6;
}

.mainWrapper .contentContainer .quadrant .top .topLeft.boxx.active {
  width: 100%;
  height: 100vh;
}

.mainWrapper .contentContainer .quadrant .top .topLeft.boxx.none {
  display: none;
}

.mainWrapper .contentContainer .quadrant .top .topRight {
  width: 50%;
  position: relative;
  cursor: pointer;
  height: 100%;
  text-align: center;
  text-decoration: none;
  color: white;
  background-color: #6b705c;
  overflow: hidden;
}

.mainWrapper .contentContainer .quadrant .top .topRight img {
  width: 25%;
  position: absolute;
  bottom: -23%;
  left: -12%;
}

.mainWrapper .contentContainer .quadrant .top .topRight h1 {
  font-size: 5.2vw;
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  top: 50%;
  font-style: italic;
  width: 100%;
  color: #585555;
}

.mainWrapper .contentContainer .quadrant .top .topRight .image {
  position: absolute;
  bottom: 0;
  left: 0;
}

.mainWrapper .contentContainer .quadrant .top .topRight:hover {
  opacity: 0.9;
}

.mainWrapper .contentContainer .quadrant .top .topRight.boxx.active {
  width: 100%;
  height: 100vh;
}

.mainWrapper .contentContainer .quadrant .top .topRight.boxx.none {
  display: none;
}

.mainWrapper .contentContainer .quadrant .bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 50%;
}

.mainWrapper .contentContainer .quadrant .bottom .bottomRight {
  width: 50%;
  height: 100%;
  position: relative;
  cursor: pointer;
  text-align: center;
  color: #6b705c;
  background-color: #ffe8d6;
  text-decoration: none;
  overflow: hidden;
}

.mainWrapper .contentContainer .quadrant .bottom .bottomRight img {
  width: 25%;
  position: absolute;
  top: -23%;
  right: -12%;
}

.mainWrapper .contentContainer .quadrant .bottom .bottomRight h1 {
  font-family: "Alegreya", serif;
  font-size: 5.2vw;
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  top: 50%;
  font-style: italic;
  width: 100%;
  color: #585555;
}

.mainWrapper .contentContainer .quadrant .bottom .bottomRight .image {
  position: absolute;
  bottom: 0;
  left: 0;
}

.mainWrapper .contentContainer .quadrant .bottom .bottomRight:hover {
  opacity: 0.9;
}

.mainWrapper .contentContainer .quadrant .bottom .bottomRight.boxx.active {
  width: 100%;
  height: 100vh;
}

.mainWrapper .contentContainer .quadrant .bottom .bottomRight.boxx.none {
  display: none;
}

.mainWrapper .contentContainer .quadrant .bottom .bottomLeft {
  width: 50%;
  height: 100%;
  position: relative;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  color: white;
  background-color: #6b705c;
  overflow: hidden;
}

.mainWrapper .contentContainer .quadrant .bottom .bottomLeft img {
  width: 25.5%;
  position: absolute;
  top: -23.6%;
  left: -12.7%;
}

.mainWrapper .contentContainer .quadrant .bottom .bottomLeft h1 {
  font-family: "Alegreya", serif;
  font-size: 5.2vw;
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  top: 50%;
  font-style: italic;
  width: 100%;
  color: #585555;
}

.mainWrapper .contentContainer .quadrant .bottom .bottomLeft .image {
  position: absolute;
  bottom: 0;
  right: 0;
}

.mainWrapper .contentContainer .quadrant .bottom .bottomLeft:hover {
  opacity: 0.9;
}

.mainWrapper .contentContainer .quadrant .bottom .bottomLeft.boxx.active {
  width: 100%;
  height: 100vh;
}

.mainWrapper .contentContainer .quadrant .bottom .bottomLeft.boxx.none {
  display: none;
}

.mainWrapper .contentContainer .quadrant .centerImage {
  width: 13%;
  border-radius: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: 1s;
  transition: 1s;
  -webkit-animation: rotate 1.4s;
          animation: rotate 1.4s;
}

.mainWrapper .contentContainer .quadrant a.blogLink {
  position: absolute;
  bottom: 2%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: #ffffff;
  padding: 10px;
  border-radius: 25px;
  text-decoration: none;
  color: black;
}

.mainWrapper .contentContainer .quadrant a:hover.blogLink {
  box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.75);
}

.mainWrapper .footer {
  width: 100%;
  background-color: white;
  padding: 2% 8% 2% 8%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.mainWrapper .footer a {
  text-decoration: none;
  color: black;
}

.mainWrapper .cornerArrowTL {
  position: fixed;
  cursor: pointer;
  z-index: 100;
  width: 8%;
  top: 0vh;
  left: 0vw;
  text-align: center;
  text-decoration: none;
  color: black;
  font-weight: 600;
}

.mainWrapper .cornerArrowTL img {
  width: 100%;
  -webkit-animation: cornerRotate 2.4s infinite linear;
          animation: cornerRotate 2.4s infinite linear;
  border-radius: 50%;
}

.mainWrapper .cornerArrowTR {
  position: fixed;
  cursor: pointer;
  z-index: 100;
  width: 8%;
  top: 0vh;
  right: 0vw;
  text-align: center;
  text-decoration: none;
  color: black;
  font-weight: 600;
}

.mainWrapper .cornerArrowTR img {
  width: 100%;
  -webkit-animation: cornerRotate 2.4s infinite linear;
          animation: cornerRotate 2.4s infinite linear;
  border-radius: 50%;
}

.mainWrapper .cornerArrowBR {
  position: fixed;
  cursor: pointer;
  z-index: 100;
  width: 8%;
  bottom: 0;
  right: 0vw;
  color: black;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
}

.mainWrapper .cornerArrowBR img {
  width: 100%;
  -webkit-animation: cornerRotate 2.4s infinite linear;
          animation: cornerRotate 2.4s infinite linear;
  border-radius: 50%;
}

.mainWrapper .cornerArrowBL {
  position: fixed;
  cursor: pointer;
  z-index: 100;
  width: 8%;
  bottom: 0;
  left: 0vw;
  text-decoration: none;
  color: black;
  text-align: center;
  font-weight: 600;
}

.mainWrapper .cornerArrowBL img {
  width: 100%;
  -webkit-animation: cornerRotate 2.4s infinite linear;
          animation: cornerRotate 2.4s infinite linear;
  border-radius: 50%;
}

@media (min-width: 320px) and (max-width: 768px) {
  .mainWrapper .contentContainer .contactBox .stripedLotus {
    display: none;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .mainWrapper h1 {
    font-size: 50px;
  }
  .mainWrapper .corner {
    width: 10% !important;
  }
  .mainWrapper .cornerArrowTL img {
    width: 150%;
  }
  .mainWrapper .quadrant .centerImage {
    width: 30%;
  }
  .mainWrapper .contentContainer .blogs {
    width: 100%;
  }
  .mainWrapper .contentContainer .cAR {
    font-size: 35px;
  }
  .mainWrapper .contentContainer .readingsPage {
    text-align: center;
  }
  .mainWrapper .contentContainer .readingsPage .readings {
    text-align: center;
  }
  .mainWrapper .contentContainer .readingsPage .cards {
    height: unset;
  }
  .mainWrapper .contentContainer .readingsPage .cards .cardd {
    margin: 7px 0.5%;
  }
  .mainWrapper .contentContainer .readingsPage .cards .cardd .image img {
    width: 100%;
  }
  .mainWrapper .contentContainer .intro h1 {
    font-size: 35px;
  }
  .mainWrapper .contentContainer .myStory .stripedLotus {
    display: none;
  }
  .mainWrapper .contentContainer .myStory .content {
    width: unset;
    padding: unset;
    text-align: center !important;
  }
  .mainWrapper .contentContainer .myStory .content h1 {
    font-size: 30px;
  }
  .mainWrapper .contentContainer .hero {
    padding: unset;
  }
  .mainWrapper .contentContainer .hero .sub .textBox {
    padding: 15px;
  }
  .mainWrapper .contentContainer .testimonies h1 {
    font-size: 35px;
  }
  .mainWrapper .contentContainer .testimonies .testimony {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 15px;
  }
  .mainWrapper .contentContainer .testimonies .testimony .image {
    width: 50%;
  }
  .mainWrapper .contentContainer .testimonies .testimony .text {
    text-align: center !important;
  }
  .mainWrapper .contentContainer .testimonies .testimony .text .content {
    text-align: center !important;
  }
  .mainWrapper .contentContainer .testimonies .testimony.animate__fadeInLeftBig {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .mainWrapper .contentContainer .contactBox .stripedLotus {
    display: none;
  }
  .mainWrapper .contentContainer .contactBox .contactForm form button {
    width: unset;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .mainWrapper .topBar .navBar {
    width: 60%;
  }
  .mainWrapper .contentContainer .readingsPage .cards {
    height: unset;
  }
  .mainWrapper .contentContainer .hero .sub .textBox h1 {
    font-style: italic;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .mainWrapper .contentContainer .hero .sub .textBox h1 {
    font-style: italic;
  }
  .mainWrapper .contentContainer .testimonies {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .mainWrapper .contentContainer .testimonies .testimony {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 1075px) {
  .mainWrapper .topBar .navBar {
    width: 100%;
  }
  .mainWrapper .contentContainer .readingsPage {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .mainWrapper .contentContainer .readingsPage .readings .cards .cardd .text .detail {
    font-size: 20px;
  }
  .mainWrapper .contentContainer .hero {
    width: 100%;
  }
  .mainWrapper .contentContainer .hero .sub {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: unset;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .mainWrapper .contentContainer .hero .sub .image {
    position: unset;
    width: 80%;
    text-align: center;
  }
  .mainWrapper .contentContainer .hero .sub .image img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
  .mainWrapper .contentContainer .hero .sub .textBox {
    width: 100%;
    position: unset;
    -webkit-transform: unset;
            transform: unset;
    max-width: unset;
    margin-top: 10px;
  }
  .mainWrapper .contentContainer .hero .sub .textBox h1 {
    font-family: "Alegreya", serif !important;
    font-size: 56px;
    text-align: center;
    margin-bottom: 2%;
    font-style: italic;
  }
  .mainWrapper .contentContainer .hero .sub .textBox p {
    font-size: 20px;
  }
  .mainWrapper .contentContainer .hero .sub a.dyr {
    margin-left: unset;
    width: unset;
    position: unset;
    -webkit-transform: unset;
            transform: unset;
  }
  .mainWrapper .contentContainer .testimonies {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .mainWrapper .contentContainer .testimonies .testimony {
    width: 100%;
  }
  .mainWrapper .contentContainer .contactSection {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .mainWrapper .contentContainer .contactSection .right {
    width: 100%;
  }
  .mainWrapper .contentContainer .broucher .title {
    font-size: 5vw;
  }
  .mainWrapper .contentContainer .cards .cardd {
    width: 100%;
    margin-left: unset !important;
  }
  .mainWrapper .contentContainer .cards .cardd .text .detail {
    font-size: unset;
  }
  .mainWrapper .contentContainer .contactBox .mailSection {
    width: 100%;
  }
  .mainWrapper .contentContainer .contactBox .contactForm {
    width: 100%;
  }
  .mainWrapper .contentContainer .contactBox .image {
    display: none;
  }
  .mainWrapper .footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .mainWrapper .footer span {
    margin: 10px 0;
  }
}

@media (min-width: 320px) and (max-width: 420px) {
  .mainWrapper .topBar .navBar {
    font-size: 3.5vw;
  }
}

@-webkit-keyframes rotate {
  from {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(720deg);
            transform: translate(-50%, -50%) rotate(720deg);
  }
}

@keyframes rotate {
  from {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(720deg);
            transform: translate(-50%, -50%) rotate(720deg);
  }
}

@-webkit-keyframes cornerRotate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes cornerRotate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media (min-width: 1400px) and (max-width: 2000px) {
  .mainWrapper .contentContainer .testimonies {
    margin-top: 65px;
  }
}

@media (min-width: 2000px) and (max-width: 2500px) {
  .mainWrapper .contentContainer .testimonies {
    margin-top: 10%;
  }
}

@media (min-width: 320px) and (max-width: 600px) {
  .mainWrapper .contentContainer .blogs {
    width: 90%;
  }
  .mainWrapper .contentContainer .blogs .blogCard .details .centerSection {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .mainWrapper .contentContainer .blogs .blogCard .details .centerSection .blogTitle {
    font-family: "Playfair Display", serif !important;
    font-size: 3vw;
  }
  .mainWrapper .contentContainer .blogs .blogCard .details .centerSection .shortBrief {
    font-size: 2.3vw;
  }
  .mainWrapper .contentContainer .blogs .blogCard .details .bottomSection .right .likeCount {
    font-size: 1.7vw;
  }
}
